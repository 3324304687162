import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./Modal.css";

class Modal extends Component {
  constructor() {
    super();
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    const { isModal } = this.props;

    if (isModal) {
      disableBodyScroll(this.modalRef.current);
    }
  }

  componentWillUnmount() {
    enableBodyScroll(this.modalRef.current);
  }

  //Category: 289938 = book a massage treatment
  //Category: 78329 = redeem a voucher
  render() {
    const { id, modalType } = this.props.match.params;
    let srcUrl =
      "https://bookings.gettimely.com/essencemassage/bb/" + modalType;
    if (modalType === "book") {
      if (id > 0) {
        srcUrl = srcUrl + `?category=289938&product=${id}%3ASV`;
      }
    } else {
      if (id > 0) {
        srcUrl = srcUrl + "?giftVoucherId=" + id;
      }
    }

    if (this.props.isModal) {
      return (
        <div
          ref={this.modalRef}
          className="modal-wrapper"
          onClick={() => this.props.history.goBack()}
        >
          <div className="modal" onClick={(e) => e.stopPropagation()}>
            {/* <h1>{id}</h1> */}
            <iframe
              className="timely-booking"
              title="Book Your Massage"
              src={srcUrl}
            ></iframe>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(Modal);
