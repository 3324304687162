import "./Vouchers.css";
import { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import MediaQuery from "react-responsive";
import banner from "./Images/Voucher-Cropped.jpeg";
import VouchersIntro from "./VouchersIntro";

class Vouchers extends Component {
  constructor() {
    super();

    this.GetTreatmentVouchers = this.GetTreatmentVouchers.bind(this);
    this.setSelectedVoucher = this.setSelectedVoucher.bind(this);
    const voucherOptions = this.GetTreatmentVouchers();
    this.state = {
      voucherOptions,
      voucherPrice: voucherOptions[0].price,
      voucherId: voucherOptions[0].value,
      timelyId: voucherOptions[0].id,
    };
  }

  setSelectedVoucher(voucher) {
    this.setState({
      voucherId: voucher.value,
      voucherPrice: voucher.price,
      timelyId: voucher.id,
    });
  }

  componentDidMount() {
    setTimeout(function () {
      alert(
        "OUR GIFT VOUCHERS ARE VALID FOR 6 MONTHS ONLY - We recommend the recipient books an appointment the day of receiving the voucher so they don't forget. They can book up to 6 months in the future using our simple online booking system."
      );
    }, 1000);
  }

  render() {
    const voucherOptions = this.state.voucherOptions;
    const customOptions = this.GetCustomVoucherAmounts();
    const customVoucherBlurb = "Custom Value Vouchers! You get to decide what value you would like to spend. Standard massage treatments range in price from $70 through to $150. Standard massage prices are as follows:";
    const voucherBlurb = "Purchase a specific massage treatment that you know they will love! If they decide they would like a different massage treatment than the one you select they can change it to a massage treatment of equal value or choose to pay the difference at their appointment.";
    let { voucherPrice, timelyId } = this.state;
    let modalPath = "/modal/purchase/0";
    const modalPathId = `/modal/purchase/${timelyId}`;
    return (
      <div className="vouchers">
        <MediaQuery minWidth={1224}>
          <div className="banner-container">
            <img className="banner-image" src={banner} alt="Back Massage" />
          </div>
          <div className="contentBody">
            <div className="voucher-body">
              <VouchersIntro />
              <div className="vouchers-container">
                <div className="test">
                  <p>
                    <strong>Select your Massage Treatment voucher :</strong>
                  </p>
                  <p>{voucherBlurb}</p>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={voucherOptions[0]}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isSearchable={true}
                    name="color"
                    options={voucherOptions}
                    onChange={this.setSelectedVoucher}
                  />
                  <p>
                    <strong>Price :</strong> $ {voucherPrice}
                  </p>
                  <div className="buy-now">
                    <Link
                      className="buyNowLink"
                      to={{
                        pathname: modalPathId,
                        state: { modal: true },
                      }}
                    >
                      <div className="buyNowText">BUY NOW</div>
                    </Link>
                  </div>
                </div>
                <div className="test">
                  <p>
                    <strong>Choose a Custom Value voucher :</strong>
                  </p>
                  <p>
                    {customVoucherBlurb}
                    <ul>
                      {
                        customOptions.map((option) => (
                          <li key={option.label}>{option.label} - {option.price}</li>
                        ))
                      }
                    </ul>
                  </p>
                  <div className="buy-now">
                    <Link
                      className="buyNowLink"
                      to={{
                        pathname: modalPath,
                        state: { modal: true },
                      }}
                    >
                      <div className="buyNowText">BUY NOW</div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
          <MediaQuery minWidth={414}>
            <div className="contentBody">
              <div className="voucher-body-tablet">
                <VouchersIntro />
                <div className="vouchers-container-mobile">
                  <div className="vouchers-general-mobile">
                    <p>
                      <strong>Select your Massage Treatment voucher :</strong>
                    </p>
                    <p>{voucherBlurb}</p>
                    <Select
                      className="vouchers-select"
                      classNamePrefix="select"
                      defaultValue={voucherOptions[0]}
                      isDisabled={false}
                      isLoading={false}
                      isClearable={false}
                      isSearchable={true}
                      name="color"
                      options={voucherOptions}
                      onChange={this.setSelectedVoucher}
                    />
                    <p>
                      <strong>Price :</strong> $ {voucherPrice}
                    </p>
                    <div className="buy-now-mobile">
                      <Link
                        className="buyNowLink"
                        to={{
                          pathname: modalPathId,
                          state: { modal: true },
                        }}
                      >
                        <div className="buyNowText">BUY NOW</div>
                      </Link>
                    </div>
                  </div>
                  <div className="vouchers-general-mobile">
                    <p>
                      <strong>Choose a Custom Value voucher :</strong>
                    </p>
                    <p>
                      {customVoucherBlurb}
                      <ul>
                      {
                        customOptions.map((option) => (
                          <li key={option.label}>{option.label} - {option.price}</li>
                        ))
                      }
                      </ul>
                    </p>
                    <div className="buy-now-mobile">
                      <Link
                        className="buyNowLink"
                        to={{
                          pathname: modalPath,
                          state: { modal: true },
                        }}
                      >
                        <div className="buyNowText">BUY NOW</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </MediaQuery>
        </MediaQuery>
        <MediaQuery maxWidth={414}>
          <div className="contentBody">
            <div className="voucher-body-mobile">
              <VouchersIntro />
              <div className="vouchers-container-mobile">
                <div className="vouchers-general-mobile">
                  <p>
                    <strong>Select your Massage Treatment voucher :</strong>
                  </p>
                  <p>{voucherBlurb}</p>
                  <Select
                    className="vouchers-select"
                    classNamePrefix="select"
                    defaultValue={voucherOptions[0]}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isSearchable={true}
                    name="color"
                    options={voucherOptions}
                    onChange={this.setSelectedVoucher}
                  />
                  <p>
                    <strong>Price :</strong> $ {voucherPrice}
                  </p>
                  <div className="buy-now-mobile">
                    <Link
                      className="buyNowLink"
                      to={{
                        pathname: modalPathId,
                        state: { modal: true },
                      }}
                    >
                      <div className="buyNowText">BUY NOW</div>
                    </Link>
                  </div>
                </div>
                <div className="vouchers-general-mobile">
                  <p>
                    <strong>Choose a Custom Value voucher :</strong>
                  </p>
                  <p>
                    {customVoucherBlurb}
                    <ul>
                      {
                        customOptions.map((option) => (
                          <li key={option.label}>{option.label} - {option.price}</li>
                        ))
                      }
                    </ul>
                  </p>
                  <div className="buy-now-mobile">
                    <Link
                      className="buyNowLink"
                      to={{
                        pathname: modalPath,
                        state: { modal: true },
                      }}
                    >
                      <div className="buyNowText">BUY NOW</div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }

  GetCustomVoucherAmounts() {
    return [
      {
        label: "30 minute massage",
        price: "$70"
      },
      {
        label: "45 minute massage",
        price: "$90"
      },
      {
        label: "60 minute massage",
        price: "$110"
      },
      {
        label: "90 minute massage",
        price: "$150"
      }
    ]
  }

  GetTreatmentVouchers() {
    return [
      {
        label: "Back, Neck & Shoulder - 30 minute appt",
        value: 1,
        price: "70.00",
        id: "40666",
      },
      {
        label: "Back, Neck & Shoulder - 45 minute appt",
        value: 2,
        price: "85.00",
        id: "40667",
      },
      {
        label: "Back, Neck & Shoulder - 60 minute appt",
        value: 3,
        price: "105.00",
        id: "33413",
      },
      {
        label: "Full Body Restorative Massage - 30 minute appt",
        value: 4,
        price: "70.00",
        id: "113521",
      },
      {
        label: "Full Body Restorative Massage - 45 minute appt",
        value: 5,
        price: "90.00",
        id: "113522",
      },
      {
        label: "Full Body Restorative Massage - 60 minute appt",
        value: 6,
        price: "110.00",
        id: "113533",
      },
      {
        label: "Full Body Restorative Massage - 90 minute appt",
        value: 7,
        price: "150.00",
        id: "113535",
      },
      {
        label: "Sports Massage - 30 minute appt",
        value: 8,
        price: "70.00",
        id: "113536",
      },
      {
        label: "Sports Massage - 45 minute appt",
        value: 9,
        price: "90.00",
        id: "113539",
      },
      {
        label: "Sports Massage - 60 minute appt",
        value: 10,
        price: "110.00",
        id: "113540",
      },
      {
        label: "Sports Massage - 90 minute appt",
        value: 11,
        price: "150.00",
        id: "113541",
      },
      {
        label: "Remedial Deep Tissue Massage - 30 minute appt",
        value: 12,
        price: "70.00",
        id: "113542",
      },
      {
        label: "Remedial Deep Tissue Massage - 45 minute appt",
        value: 13,
        price: "90.00",
        id: "113543",
      },
      {
        label: "Remedial Deep Tissue Massage - 60 minute appt",
        value: 14,
        price: "110.00",
        id: "113545",
      },
      {
        label: "Remedial Deep Tissue Massage - 90 minute appt",
        value: 15,
        price: "150.00",
        id: "113546",
      },
      {
        label: "Pregnancy Massage - 60 minute appt",
        value: 16,
        price: "120.00",
        id: "113547",
      },
      {
        label: "Pregnancy Massage - 90 minute appt",
        value: 17,
        price: "160.00",
        id: "113549",
      },
      {
        label: "Swedish Relaxation - 30 minute appt",
        value: 18,
        price: "70.00",
        id: "113550",
      },
      {
        label: "Swedish Relaxation - 45 minute appt",
        value: 19,
        price: "90.00",
        id: "113551",
      },
      {
        label: "Swedish Relaxation - 60 minute appt",
        value: 20,
        price: "110.00",
        id: "113552",
      },
      {
        label: "Swedish Relaxation - 90 minute appt",
        value: 21,
        price: "150.00",
        id: "113553",
      },
      {
        label: "Traditional Thai Yoga Massage - 60 minute appt",
        value: 22,
        price: "130.00",
        id: "235955",
      },
      {
        label: "Traditional Thai Yoga Massage - 90 minute appt",
        value: 23,
        price: "170.00",
        id: "235956",
      },
      {
        label: "Traditional Thai Yoga Massage - 120 minute appt",
        value: 24,
        price: "210.00",
        id: "235958",
      },
      {
        label: "Hot Stone Therapy - 45 minute appt",
        value: 25,
        price: "130.00",
        id: "113516",
      },
      {
        label: "Hot Stone Therapy - 60 minute appt",
        value: 26,
        price: "150.00",
        id: "113519",
      },
      {
        label: "Hot Stone Therapy - 90 minute appt",
        value: 27,
        price: "190.00",
        id: "113520",
      },
      {
        label: "Therapeutic Cupping Massage - 45 minute appt",
        value: 28,
        price: "100.00",
        id: "113558",
      },
      {
        label: "Therapeutic Cupping Massage - 60 minute appt",
        value: 29,
        price: "120.00",
        id: "113559",
      },
      {
        label: "Therapeutic Cupping Massage - 90 minute appt",
        value: 30,
        price: "160.00",
        id: "113561",
      },
    ];
  }
}

export default Vouchers;
