import React, { Component } from "react";
import "./App.css";
import { Route, Switch, withRouter } from "react-router-dom";
import Header from "./Header/Header.js";
import Home from "./Home/Home.js";
import About from "./About/About.js";
import Service from "./Service/Service";
import ServiceFilter from "./Service/ServiceFilter";
import Vouchers from "./Vouchers/Vouchers.js";
import Contact from "./Contact/Contact.js";
import FAQ from "./FAQ/FAQ.js";
import Privacy from "./Privacy/Privacy.js";
import Cancellation from "./Cancellation/Cancellation.js";
import Footer from "./Footer/Footer.js";
import Modal from "./Utils/Modal.js";

class App extends Component {
  constructor(props) {
    super(props);
    this.previousLocation = this.props.location;
  }

  componentDidUpdate() {
    const { location } = this.props;
    if (!(location.state && location.state.modal)) {
      this.previousLocation = this.props.location;
    }
    window.scrollTo(0, 0);
  }

  render() {
    const { location } = this.props;
    const isModal =
      location.state &&
      location.state.modal &&
      this.previousLocation !== location;

    return (
      <div className="App">
        {isModal ? (
          <Route exact path="/modal/:modalType/:id">
            <Modal isModal={isModal} />
          </Route>
        ) : null}
        <Header />
        <Switch location={isModal ? this.previousLocation : location}>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/services/:servicetype">
            <ServiceFilter />
          </Route>
          <Route path="/service">
            <Service />
          </Route>
          <Route path="/vouchers">
            <Vouchers />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/faq">
            <FAQ />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/cancellation">
            <Cancellation />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
