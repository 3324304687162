import React from "react";

const OpeningHours = (props) => {
  return (
    <React.Fragment>
      <div className="opening-hours">
        <div>Monday</div>
        <div>9.30pm - 8:00pm</div>
      </div>
      <div className="opening-hours">
        <div>Tuesday - Wednesday</div>
        <div>9.30am - 2.30pm</div>
      </div>
      <div className="opening-hours">
        <div>Thursday</div>
        <div>9:30pm - 8:00pm</div>
      </div>
      <div className="opening-hours">
        <div>Friday - Sunday</div>
        <div>Closed or appointment by arrangement</div>
      </div>
    </React.Fragment>
  );
};

export default OpeningHours;
