import "./About.css";
import { Component } from "react";
import MediaQuery from "react-responsive";
import AllTherapists from "./AllTherapists";
import banner from "./Images/About-Cropped.jpeg";

class About extends Component {
  // constructor() {
  // 	super();

  // }

  render() {
    return (
      <div className="about">
        <MediaQuery minWidth={1224}>
          <div className="banner-container">
            <img className="banner-image" src={banner} alt="Back Massage" />
          </div>
          <div className="contentBody">
            <div className="about-body">
              <AllTherapists></AllTherapists>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
          <MediaQuery minWidth={414}>
            <div className="contentBody">
              <div className="about-body-tablet">
                <AllTherapists></AllTherapists>
              </div>
            </div>
          </MediaQuery>
        </MediaQuery>
        <MediaQuery maxWidth={414}>
          <div className="contentBody">
            <div className="about-body-mobile">
              <AllTherapists></AllTherapists>
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default About;
