import React from "react";
import { Link } from "react-router-dom";
import cupping from "./Images/Cupping-Massage.svg";
import hotstone from "./Images/Hot-Stone-Therapy.svg";
import myofascial from "./Images/Myofascial-Massage.svg";
import neckbackshoulder from "./Images/Neck-Back-Shoulders.svg";
import prenatal from "./Images/Prenatal-Massage.svg";
import deeptissue from "./Images/Deep-Tissue-Massage.svg";
import restorative from "./Images/Restorative-Massage.svg";
import sports from "./Images/Sports-Massage.svg";
import swedish from "./Images/Swedish-Relaxation.svg";
import "./essence-service.css";

const EssenceServiceMobile = (props) => {
  return (
    <div className="home-services-container">
      <Link
        className="homeService-link-mobile"
        to="/services/back-neck-shoulders"
      >
        <img
          className="serviceImage"
          src={neckbackshoulder}
          alt="Back, Neck and Shoulder Massage"
          aria-label="Back, Neck and Shoulder Massage"
        ></img>
        <span className="navLink">Back, Neck and Shoulder Massage</span>
      </Link>
      <Link className="homeService-link-mobile" to="/services/deep-tissue">
        <img
          className="serviceImage"
          src={deeptissue}
          alt="Remedial Deep Tissue Massage"
          aria-label="Remedial Deep Tissue Massage"
        ></img>
        <span className="navLink">Remedial Deep Tissue Massage</span>
      </Link>
      <Link className="homeService-link-mobile" to="/services/restorative">
        <img
          className="serviceImage"
          src={restorative}
          alt="Restorative Massage"
          aria-label="Restorative Massage"
        ></img>
        <span className="navLink">Restorative Massage</span>
      </Link>
      <Link className="homeService-link-mobile" to="/services/pregnancy">
        <img
          className="serviceImage"
          src={prenatal}
          alt="Pregnancy Massage"
          aria-label="Pregnancy Massage"
        ></img>
        <span className="navLink">Pregnancy Massage</span>
      </Link>
      <Link className="homeService-link-mobile" to="/services/sports">
        <img
          className="serviceImage"
          src={sports}
          alt="Sports Massage"
          aria-label="Sports Massage"
        ></img>
        <span className="navLink">Sports Massage</span>
      </Link>
      <Link className="homeService-link-mobile" to="/services/hotstone-therapy">
        <img
          className="serviceImage"
          src={hotstone}
          alt="Hot Stone Therapy"
          aria-label="Hot Stone Therapy"
        ></img>
        <span className="navLink">Hot Stone Therapy</span>
      </Link>
      <Link
        className="homeService-link-mobile"
        to="/services/swedish-relaxation"
      >
        <img
          className="serviceImage"
          src={swedish}
          alt="Swedish Relaxation Massage"
          aria-label="Swedish Relaxation Massage"
        ></img>
        <span className="navLink">Swedish Relaxation Massage</span>
      </Link>
      <Link
        className="homeService-link-mobile"
        to="/services/therapeutic-cupping"
      >
        <img
          className="serviceImage"
          src={cupping}
          alt="Therapeutic Cupping Massage"
          aria-label="Therapeutic Cupping Massage"
        ></img>
        <span className="navLink">Therapeutic Cupping Massage</span>
      </Link>
      <Link className="homeService-link-mobile" to="/services/traditional-thai">
        <img
          className="serviceImage"
          src={myofascial}
          alt="Traditional Thai Yoga Massage"
          aria-label="Traditional Thai Yoga Massage"
        ></img>
        <span className="navLink">Traditional Thai Yoga Massage</span>
      </Link>
    </div>
  );
};

export default EssenceServiceMobile;
