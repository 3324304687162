import React from "react";

import Therapist from "./Therapist";

import kirsten from "./Images/Kirsten.jpg";

const AllTherapists = (props) => {
  return (
    <React.Fragment>
      <h1>About</h1>
      <p>Restoring Balance, Health &amp; Well-Being Through Massage.</p>
      <p>
        We are dedicated to providing the best results for you. A small
        consultation before every session ensures you are getting the best
        treatment, resulting in the highest results for you. We live in a busy
        world and we deserve to take time for ourselves.
      </p>
      <p>
        Specialists In:
        <br />
        Traditional Thai Yoga Massage | Sports Massage | Swedish Relaxation
        Massage | Restorative (Maintenance) Massage | Deep Tissue Massage |
        Back, Neck &amp; Shoulder Massage | Hot Stone Therapy | Pregnancy
        Massage | Therapeutic Cupping Massage
      </p>
      <p>
        We look forward to meeting and working with you to create a happier,
        healthier you.
      </p>

      <h2>Meet the team</h2>
      <div className="therapist-container">
        <Therapist
          image={kirsten}
          imageTitle="Kirsten Polglase - Certified Massage Therapist"
          title="Kirsten Polglase - 0226469949"
          role="Certified Massage Therapist"
          description="I chose to become a Massage Therapist because I love helping people to be the best version of themselves."
          certs={[
            "Cert. Thai Yoga Massage, Sunshine Massage School, Chiang Mai, 2016",
            "Advanced Cert. Thai Yoga Massage,  Loi Kroh Thai Yoga & Massage School, Chiang Mai, 2018",
            "Cert. Relaxation Massage Therapy, NZ College of Massage, 2016",
            "Cert. L5 Remedial Deep Tissue Massage Therapy,  Holistic Health Training, 2017",
            "Cert. Prenatal Massage, Inst. for Integrative Healthcare 2017",
            "Cert. Human Nutrition, Holistic Health Training 2024",
          ]}
        ></Therapist>
      </div>
    </React.Fragment>
  );
};

export default AllTherapists;
