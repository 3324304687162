import React from "react";
import "./Therapist.css";
import MediaQuery from "react-responsive";

const Therapist = (props) => {
  const { image, imageTitle, title, role, description, certs } = props;

  return (
    <React.Fragment>
      <MediaQuery minWidth={414}>
        <div className="therapist-component">
          <img className="therapist-image" src={image} alt={imageTitle}></img>
          <h3>{title}</h3>
          <p>
            <strong>{role}</strong>
          </p>
          <p>{description}</p>
          <ul>
            {certs.map((cert) => (
              <li key={cert}>{cert}</li>
            ))}
          </ul>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={414}>
        <div className="therapist-component-mobile">
          <img
            className="therapist-image-mobile"
            src={image}
            alt={imageTitle}
          ></img>
          <h3>{title}</h3>
          <p>
            <strong>{role}</strong>
          </p>
          <p>{description}</p>
          <ul>
            {certs.map((cert) => (
              <li key={cert}>{cert}</li>
            ))}
          </ul>
        </div>
      </MediaQuery>
    </React.Fragment>
  );
};

export default Therapist;
