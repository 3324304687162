import "./Privacy.css";
import { Component } from "react";
import MediaQuery from "react-responsive";
import banner from "./Images/Privacy-Cropped.jpeg";
import PrivacyContent from "./PrivacyContent.js";

class Privacy extends Component {
  render() {
    return (
      <div className="privacy">
        <MediaQuery minWidth={1224}>
          <div className="banner-container">
            <img className="banner-image" src={banner} alt="Back Massage" />
          </div>
          <div className="contentBody">
            <div className="privacy-body">
              <PrivacyContent />
            </div>
          </div>
        </MediaQuery>

        <MediaQuery maxWidth={1224}>
          <MediaQuery minWidth={414}>
            <div className="contentBody">
              <div className="privacy-body-tablet">
                <PrivacyContent />
              </div>
            </div>
          </MediaQuery>
        </MediaQuery>
        <MediaQuery maxWidth={414}>
          <div className="contentBody">
            <div className="privacy-body-mobile">
              <PrivacyContent />
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default Privacy;
