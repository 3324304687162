import ServiceTemplate from "./ServiceTemplate";
import MediaQuery from "react-responsive";
import "./Service.css";

import { useParams } from "react-router";

import backNeckShoulders from "./Images/back-neck-shoulders.jpeg";
import hotStone from "./Images/hot-stone-therapy.jpg";
import pregnancy from "./Images/pregnancy.jpeg";
import deepTissue from "./Images/deep-tissue.jpeg";
import restorative from "./Images/restorative.jpeg";
import sports from "./Images/sports.jpeg";
import swedish from "./Images/swedish.jpeg";
import cupping from "./Images/cupping.jpeg";
import thai from "./Images/thai.jpeg";
import banner from "./Images/Services-Cropped.jpeg";
import React from "react";

const ServiceFilter = (props) => {
  let title = "Something went wrong";
  let description = [
    "Please try refreshing the page, or returning to the home page",
  ];
  let options = {};
  let image = null;
  let { servicetype } = useParams();

  if (servicetype === "back-neck-shoulders") {
    title = "Back, Neck & Shoulder";
    description = [
      "Escape the stresses of everyday life and experience a soothing massage which targets all the places you hold the most tension. Relieve muscular aches and alleviate pains with a Back, Neck and Shoulder  Massage.",
    ];
    options = [
      { label: "30 minutes", value: 1, cost: "70", id: "634548" },
      { label: "45 minutes", value: 2, cost: "85", id: "634640" },
      { label: "60 minutes", value: 3, cost: "105", id: "634621" },
    ];
    image = backNeckShoulders;
  }
  if (servicetype === "hotstone-therapy") {
    title = "Hot Stone Therapy";
    description = [
      "Suited to people who tend to get cold or chilly feet. The heat can be both deeply relaxing and help warm up tight muscles so the therapist can work more deeply, more quickly.",
    ];
    options = [
      { label: "45 minutes", value: 1, cost: "130", id: "1129654" },
      { label: "60 minutes", value: 2, cost: "150", id: "1129656" },
      { label: "90 minutes", value: 3, cost: "190", id: "1129657" },
    ];
    image = hotStone;
  }
  if (servicetype === "pregnancy") {
    title = "Pregnancy Massage";
    description = [
      "Pregnancy massage reduces stress and promotes overall wellness. Massage relieves many of the normal discomforts experienced during pregnancy, such as backaches, stiff neck, leg cramps, headaches and edema (or swelling).",
      "We now have a specialised pregnancy table which offers an innovative breast and stomach recess feature. Please mention in your booking notes if you would like to use this table.",
    ];
    options = [
      { label: "60 minutes", value: 1, cost: "120", id: "756463" },
      { label: "90 minutes", value: 2, cost: "160", id: "757925" },
    ];
    image = pregnancy;
  }
  if (servicetype === "deep-tissue") {
    title = "Remedial Deep Tissue Massage";
    description = [
      "Deep Tissue Massage releases tension in the body through applying slow deep pressure to the deeper layers of muscles.",
    ];
    options = [
      { label: "30 minutes", value: 1, cost: "70", id: "757912" },
      { label: "45 minutes", value: 2, cost: "90", id: "757914" },
      { label: "60 minutes", value: 3, cost: "110", id: "757917" },
      { label: "90 minutes", value: 4, cost: "150", id: "757919" },
    ];
    image = deepTissue;
  }
  if (servicetype === "restorative") {
    title = "Restorative Massage";
    description = [
      "Restore you to your best self, with treatments tailored specifically to your individual changing needs.",
      "If you’re unsure which massage treatment is right for you then a Restorative Massage will be the way to go. After a five minute consultation, your therapist will help to tailor a massage treatment that is just right for you.",
    ];
    options = [
      { label: "30 minutes", value: 1, cost: "70", id: "634622" },
      { label: "45 minutes", value: 2, cost: "90", id: "634639" },
      { label: "60 minutes", value: 3, cost: "110", id: "634623" },
      { label: "90 minutes", value: 4, cost: "150", id: "634625" },
    ];
    image = restorative;
  }
  if (servicetype === "sports") {
    title = "Sports Massage";
    description = [
      "Prepare your body for optimal performance. Recover after a big event or function well during training. This sports massage will help prevention and healing of injuries to your muscles and tendons.",
    ];
    options = [
      { label: "30 minutes", value: 1, cost: "70", id: "669230" },
      { label: "45 minutes", value: 2, cost: "90", id: "669938" },
      { label: "60 minutes", value: 3, cost: "110", id: "669234" },
      { label: "90 minutes", value: 4, cost: "150", id: "669235" },
    ];
    image = sports;
  }
  if (servicetype === "swedish-relaxation") {
    title = "Swedish Relaxation Massage";
    description = [
      "The perfect wind down treat. Using flowing strokes from the therapists hands, forearms and elbows with oil or balm. Gently eases muscle tension. Great for mind, body and spirit relaxation.",
    ];
    options = [
      { label: "30 minutes", value: 1, cost: "70", id: "634633" },
      { label: "45 minutes", value: 2, cost: "90", id: "669531" },
      { label: "60 minutes", value: 3, cost: "110", id: "634634" },
      { label: "90 minutes", value: 4, cost: "150", id: "634636" },
    ];
    image = swedish;
  }
  if (servicetype === "therapeutic-cupping") {
    title = "Therapeutic Cupping Massage";
    description = [
      "Cupping therapy is an ancient form of alternative medicine which originated in China. The therapist will place special cups on your skin to create suction which increases blood flow and facilitate healing. Cupping helps reduce pain, and can be thought of as a type of deep-tissue massage.",
    ];
    options = [
      { label: "45 minutes", value: 1, cost: "100", id: "1294424" },
      { label: "60 minutes", value: 2, cost: "120", id: "1329290" },
      { label: "90 minutes", value: 3, cost: "160", id: "1329291" },
    ];
    image = cupping;
  }
  if (servicetype === "traditional-thai") {
    title = "Thai Yoga Massage";
    description = [
      "This full body massage uses rhythmic compression, stretching & gentle rocking to relax & realign the body. Thai massage assists to improve your range of motion, energy levels and reduce muscle tension.",
    ];
    options = [
      { label: "60 minutes", value: 1, cost: "130", id: "0" },
      { label: "90 minutes", value: 2, cost: "170", id: "0" },
      { label: "120 minutes", value: 3, cost: "210", id: "0" },
    ];
    image = thai;
  }
  return (
    <React.Fragment>
      <MediaQuery minWidth={1224}>
        <div className="banner-container">
          <img className="banner-image" src={banner} alt="Back Massage" />
        </div>
      </MediaQuery>
      <ServiceTemplate
        title={title}
        description={description}
        treatmentOptions={options}
        image={image}
      />
    </React.Fragment>
  );
};

export default ServiceFilter;
