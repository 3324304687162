import React, { Component } from "react";
import jumbo1 from "./../Images/jumbo1-cropped.jpg";
import jumbo2 from "./../Images/jumbo2-cropped.jpeg";
import jumbo3 from "./../Images/jumbo3-cropped.jpeg";
import jumbo4 from "./../Images/jumbo4-cropped.jpeg";
import jumbo5 from "./../Images/jumbo5-cropped.jpeg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

class HomeCarousel extends Component {
  render() {
    return (
      <Carousel showThumbs={false}>
        <div>
          <img
            src={jumbo1}
            alt="Hot stone massage"
            aria-label="Hot stone massage"
          />
        </div>
        <div>
          <img
            src={jumbo2}
            alt="Leg stretching massage"
            aria-label="Leg stretching massage"
          />
        </div>
        <div>
          <img src={jumbo3} alt="Back massage" aria-label="Back massage" />
        </div>
        <div>
          <img src={jumbo4} alt="Back massage" aria-label="Back massage" />
        </div>
        <div>
          <img
            src={jumbo5}
            alt="Shoulder and neck massage"
            aria-label="Shoulder and neck massage"
          />
        </div>
      </Carousel>
    );
  }
}

export default HomeCarousel;
