import "./Header.css";
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import logoEssence from "../Images/logo-essence.svg";
import hamburgerIcon from "./Images/menu-icon.svg";
import fbLogo from "./Images/facebook.svg";
import instaLogo from "./Images/instagram.svg";
import tripAdvisorLogo from "./Images/tripadvisor.svg";
import googleLogo from "./Images/google-plus.svg";
import jumbo1 from "./../Images/jumbo1-cropped.jpg";
import MediaQuery from "react-responsive";
import MobileNav from "../Navigation/MobileNav";

class Header extends Component {
  constructor() {
    super();
    this.state = {
      selectedPage: "home",
      menuStage: "closed",
      redirect: null,
    };

    this.onLinkClicked = this.onLinkClicked.bind(this);
    this.onHamburgerClicked = this.onHamburgerClicked.bind(this);
    this.onMenuItemClicked = this.onMenuItemClicked.bind(this);
  }

  onLinkClicked(linkName) {
    this.setState({ selectedPage: linkName });
  }

  onHamburgerClicked() {
    if (this.state.menuStage === "closed") {
      this.setState({ menuStage: "open" });
    } else {
      this.setState({ menuStage: "closed" });
    }
  }

  onMenuItemClicked(linkTo) {
    this.setState({
      selectedPage: linkTo,
      menuStage: "closed",
      redirect: "/" + linkTo,
    });
  }

  render() {
    const { selectedPage, menuStage } = this.state;
    if (this.state.redirect !== null) {
      this.setState({ redirect: null });
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <React.Fragment>
        <MediaQuery minWidth={1224}>
          <header className="App-header">
            <div className="header-container">
              <div className="logoContainer logoDimentions">
                <Link to="/">
                  <img
                    className="App-logo logoDimentions"
                    src={logoEssence}
                    alt="Essence Massage and Bodyworks Logo"
                  ></img>
                </Link>
              </div>
              <div className="headerNav">
                <div className="nav-container">
                  <Link
                    className={
                      selectedPage === "home"
                        ? "navLink navLinkPaddingRight activeLink"
                        : "navLink navLinkPaddingRight"
                    }
                    onClick={() => this.onLinkClicked("home")}
                    to="/"
                  >
                    Home
                  </Link>
                  <Link
                    className={
                      selectedPage === "about"
                        ? "navLink navLinkPaddingRight activeLink"
                        : "navLink navLinkPaddingRight"
                    }
                    onClick={() => this.onLinkClicked("about")}
                    to="/about"
                  >
                    About
                  </Link>
                  <Link
                    className={
                      selectedPage === "service"
                        ? "navLink navLinkPaddingRight activeLink"
                        : "navLink navLinkPaddingRight"
                    }
                    onClick={() => this.onLinkClicked("service")}
                    to="/service"
                  >
                    Services
                  </Link>
                  <Link
                    className={
                      selectedPage === "vouchers"
                        ? "navLink navLinkPaddingRight activeLink"
                        : "navLink navLinkPaddingRight"
                    }
                    onClick={() => this.onLinkClicked("vouchers")}
                    to="/vouchers"
                  >
                    Vouchers
                  </Link>
                  <Link
                    className={
                      selectedPage === "faq"
                        ? "navLink navLinkPaddingRight activeLink"
                        : "navLink navLinkPaddingRight"
                    }
                    onClick={() => this.onLinkClicked("faq")}
                    to="/faq"
                  >
                    FAQs
                  </Link>
                  <Link
                    className={
                      selectedPage === "contact"
                        ? "navLink activeLink"
                        : "navLink"
                    }
                    onClick={() => this.onLinkClicked("contact")}
                    to="/contact"
                  >
                    Contact
                  </Link>
                </div>
              </div>
              <div className="endNavContainer">
                <div className="voucher-button">
                  <div className="bookNow-container">
                    <a class="voucherLink" href="/vouchers">
                      GIFT VOUCHERS
                    </a>
                  </div>
                </div>
                <div className="mediaStacker">
                  <div className="mediaLinksContainer">
                    <a
                      className="mediaLink"
                      href="https://www.facebook.com/EssenceMassage/"
                    >
                      <img
                        className="fb-logo"
                        src={fbLogo}
                        alt="Essence Massage Facebook Link"
                      ></img>
                    </a>
                    <a
                      className="mediaLink mediaLinkPaddingLeft"
                      href="https://www.instagram.com/essence.massage.bodyworks/"
                    >
                      <img
                        className="insta-logo"
                        src={instaLogo}
                        alt="Essence Massage Instagram Link"
                      ></img>
                    </a>
                    <a
                      className="mediaLink mediaLinkPaddingLeft"
                      href="https://www.tripadvisor.co.nz/Attraction_Review-g255118-d12636150-Reviews-Essence_Massage_Bodyworks-Christchurch_Canterbury_Region_South_Island.html"
                    >
                      <img
                        className="trip-advisor-logo"
                        src={tripAdvisorLogo}
                        alt="Essence Massage Trip Advisor Link"
                      ></img>
                    </a>
                    <a className="mediaLink" href="https://g.co/kgs/ks7pbw">
                      <img
                        className="google-logo"
                        src={googleLogo}
                        alt="Essence Massage Google Link"
                      ></img>
                    </a>
                  </div>
                  <div className="bookNow-container">
                    <Link
                      className="bookNowLink"
                      to={{
                        pathname: "/modal/book/0",
                        state: { modal: true },
                      }}
                    >
                      BOOK NOW
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
          <MediaQuery minWidth={414}>
            <header className="App-header-tablet">
              <div className="header-container-tablet">
                <div className="header-container-start-tablet">
                  <div className="logoContainer-tablet logoDimentions-tablet">
                    <Link to="/">
                      <img
                        className="App-logo logoDimentions-tablet"
                        src={logoEssence}
                        alt="Essence Massage and Bodyworks Logo"
                      ></img>
                    </Link>
                  </div>
                </div>
                <div className="header-container-end-tablet">
                  <div className="mediaLinksContainer-tablet">
                    <a
                      className="mediaLink"
                      href="https://www.facebook.com/EssenceMassage/"
                    >
                      <img
                        className="fb-logo"
                        src={fbLogo}
                        alt="Essence Massage Facebook Link"
                      ></img>
                    </a>
                    <a
                      className="mediaLink mediaLinkPaddingLeft"
                      href="https://www.instagram.com/essence.massage.bodyworks/"
                    >
                      <img
                        className="insta-logo"
                        src={instaLogo}
                        alt="Essence Massage Instagram Link"
                      ></img>
                    </a>
                    <a
                      className="mediaLink mediaLinkPaddingLeft"
                      href="https://www.tripadvisor.co.nz/Attraction_Review-g255118-d12636150-Reviews-Essence_Massage_Bodyworks-Christchurch_Canterbury_Region_South_Island.html"
                    >
                      <img
                        className="trip-advisor-logo"
                        src={tripAdvisorLogo}
                        alt="Essence Massage Trip Advisor Link"
                      ></img>
                    </a>
                    <a className="mediaLink" href="https://g.co/kgs/ks7pbw">
                      <img
                        className="google-logo"
                        src={googleLogo}
                        alt="Essence Massage Google Link"
                      ></img>
                    </a>
                  </div>
                  <div className="hamburger-container">
                    <img
                      className={
                        menuStage === "open"
                          ? "hamburger-icon open"
                          : "hamburger-icon"
                      }
                      onClick={this.onHamburgerClicked}
                      src={hamburgerIcon}
                      alt="Menu"
                      aria-label={this.state.menuStage}
                    ></img>
                  </div>
                </div>
              </div>
              <MobileNav
                menuStage={menuStage}
                onMenuItemClicked={this.onMenuItemClicked}
              ></MobileNav>
              <div className="tablet-banner-container">
                <img
                  className="tablet-banner"
                  src={jumbo1}
                  alt="Banner - back massage"
                  aria-label="Banner - back massage"
                ></img>
              </div>
            </header>
          </MediaQuery>
        </MediaQuery>
        <MediaQuery maxWidth={414}>
          <header className="App-header-mobile">
            <div className="header-container-mobile">
              <div
                className={
                  menuStage === "open"
                    ? "logoContainer-menu"
                    : "logoContainer-mobile"
                }
              >
                <Link to="/">
                  <img
                    className={
                      menuStage === "open"
                        ? "App-logo-mobile logoDimentions-menu"
                        : "App-logo-mobile logoDimentions-mobile"
                    }
                    src={logoEssence}
                    alt="Essence Massage and Bodyworks Logo"
                  ></img>
                </Link>
              </div>

              <div className="hamburger-container">
                <img
                  className={
                    menuStage === "open"
                      ? "hamburger-icon open"
                      : "hamburger-icon"
                  }
                  onClick={this.onHamburgerClicked}
                  src={hamburgerIcon}
                  alt="Menu"
                  aria-label={this.state.menuStage}
                ></img>
              </div>
            </div>
            <MobileNav
              menuStage={menuStage}
              onMenuItemClicked={this.onMenuItemClicked}
            ></MobileNav>
            <div className="mobile-banner-container">
              <img
                className="mobile-banner"
                src={jumbo1}
                alt="Banner - back massage"
                aria-label="Banner - back massage"
              ></img>
            </div>
          </header>
        </MediaQuery>
      </React.Fragment>
    );
  }
}

export default Header;
