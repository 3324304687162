import React, { Component } from "react";
import "../Components/ReviewsSlider.css";
import MediaQuery from "react-responsive";

import startQuoteMark from "../Images/startQuote.svg";
import endQuoteMark from "../Images/endQuote.svg";

class ReviewsSlider extends Component {
  constructor() {
    super();

    let reviewList = [
      {
        quote:
          "Have had a few massages with different staff at Essence, and every time it’s such a treat. They’re very knowledgeable, professional and communicate with you to ensure you are most comfortable. Highly recommend for sore muscles and for relaxation!",
        author: "Penny Westwood",
      },
      {
        quote:
          "Pregnancy massage – AMAZING! Took the time to find out where my aches and pains were and was an all round lovely and relaxing experience. Even took the time to teach me some stretches to help with aching hips. I would recommend to all. Thanks!",
        author: "G Elia",
      },
      {
        quote:
          "Always excellent service – great care is taken to get to know you as a person and what your needs are. I have a massage here every fortnight and it has had a good impact on my back pain and stiffness. Always a friendly and professional service, would recommend to anyone.",
        author: "Liam Ambrose",
      },
      {
        quote:
          "Great massage, cares for health and as an old boy keeps me feeling young, fit and always relaxed. The clinic is clean, tidy and has a comfortable environment. A really great step forwards for bettering my health. I recommend the Thai Yoga Massage & Hot Stone Therapy!",
        author: "Graham Heazlewood",
      },
    ];

    this.state = {
      reviewList: reviewList,
      reviewListTotal: reviewList.length,
      currentReviewNumber: 0,
      isMounted: false,
    };

    this.onReviewChange = this.onReviewChange.bind(this);
  }

  componentDidMount() {
    this.setState({ isMounted: true }, () =>
      setTimeout(() => this.onReviewChange(), 1000)
    );
  }

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  onReviewChange() {
    const { isMounted } = this.state;
    if (isMounted) {
      let currentReviewNumber = this.state.currentReviewNumber;
      currentReviewNumber = currentReviewNumber + 1;
      if (currentReviewNumber === this.state.reviewListTotal) {
        currentReviewNumber = 0;
      }
      this.setState({ currentReviewNumber });
      setTimeout(() => this.onReviewChange(), 15000);
    }
  }

  render() {
    let { reviewList, currentReviewNumber } = this.state;
    return (
      <div className="reviews-slider-container">
        <MediaQuery minWidth={1224}>
          <div className="quote-container">
            <div className="quote-image-container">
              <img
                className="quote-image"
                alt="Opening quote mark"
                src={startQuoteMark}
              />
            </div>
            <div className="quote-text-container">
              <div className="quote-text">
                <p>{reviewList[currentReviewNumber].quote}</p>
              </div>
              <div className="quote-text">
                <p>
                  <strong>{reviewList[currentReviewNumber].author}</strong>
                </p>
              </div>
            </div>
            <div className="quote-image-container">
              <img
                className="quote-image"
                alt="Ending quote mark"
                src={endQuoteMark}
              />
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
          <MediaQuery minWidth={414}>
            <div className="quote-container-tablet">
              <div className="quote-image-container">
                <img
                  className="quote-image"
                  alt="Opening quote mark"
                  src={startQuoteMark}
                />
              </div>
              <div className="quote-text-container-tablet">
                <div className="quote-text">
                  <p>{reviewList[currentReviewNumber].quote}</p>
                </div>
                <div className="quote-text">
                  <p>
                    <strong>{reviewList[currentReviewNumber].author}</strong>
                  </p>
                </div>
              </div>
              <div className="quote-image-container">
                <img
                  className="quote-image"
                  alt="Ending quote mark"
                  src={endQuoteMark}
                />
              </div>
            </div>
          </MediaQuery>
        </MediaQuery>
      </div>
    );
  }
}

export default ReviewsSlider;
