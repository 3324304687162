import "./Footer.css";
import { Component } from "react";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";

import OpeningHours from "./OpeningHours";

import logo from "../Images/logo-essence.svg";
import newClientPdf from "../Files/New-Client-Intake-Form.pdf";
import pregnancyIntakePdf from "../Files/Pregnancy-Intake-Form.pdf";

class Footer extends Component {
  // constructor() {
  // 	super();

  //     }

  render() {
    const address = "100 Champion Road, Richmond";
    return (
      <footer className="App-footer">
        <MediaQuery minWidth={1224}>
          <div className="footer-container">
            <div className="footer-container-quarter">
              <img
                className="footerLogo"
                alt="Essence Massage Logo"
                src={logo}
              />
            </div>
            <div className="footer-container-quarter">
              <div className="left-align">
                <p alt="Essence Massage &amp; Bodyworks">
                  Essence Massage &amp; Bodyworks
                </p>
                <p
                  className="essence-orange"
                  alt="Contact Number: 022 646 9949"
                >
                  022 646 9949
                </p>
              </div>
              <Link className="footer-link" to="/contact">
                <div className="messageContactContainer">
                  <p className="essence-orange">
                    <strong>SEND US A MESSAGE</strong>
                  </p>
                </div>
              </Link>
            </div>
            <div className="footer-container-quarter">
              <div className="left-align">
                <p alt={address + ", Nelson 7020"}>
                  {address}
                  <br /> Nelson 7020
                </p>
              </div>
              <OpeningHours></OpeningHours>
            </div>
            <div className="footer-container-quarter top-margin">
              <Link className="footer-link" to="/">
                Home
              </Link>
              <Link className="footer-link" to="/about">
                About
              </Link>
              <Link className="footer-link" to="/services">
                Services
              </Link>
              <Link className="footer-link" to="/vouchers">
                Vouchers
              </Link>
              <Link className="footer-link" to="/privacy">
                Privacy
              </Link>
              <Link className="footer-link" to="/contact">
                Contact
              </Link>
              <Link className="footer-link" to="/cancellation">
                Cancellations
              </Link>
              <a
                className="footer-link"
                href={newClientPdf}
                download="New-Client-Intake-Form"
              >
                New Client Intake Form
              </a>
              <a
                className="footer-link"
                href={pregnancyIntakePdf}
                download="Pregnancy-Intake-Form"
              >
                Pregnancy Intake Form
              </a>
            </div>
          </div>
          <div className="copyright-bar">
            <div className="copyright-container">
              <div>&copy; Copyright 2024 Essence Massage and Bodyworks</div>
              <div>Website Developed and Maintained by Matt Tucker</div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
          <MediaQuery minWidth={715}>
            <div className="footer-container-tablet">
              <div className="footer-container-third">
                <img
                  className="footerLogo-tablet"
                  alt="Essence Massage Logo"
                  src={logo}
                />
                <Link className="footer-link-tablet" to="/contact">
                  <div className="messageContactContainer-tablet">
                    <p className="essence-orange">
                      <strong>SEND US A MESSAGE</strong>
                    </p>
                  </div>
                </Link>
              </div>
              <div className="footer-container-third">
                <div className="left-align">
                  <p alt={address + ", Nelson 7020"}>
                    {address}
                    <br /> Nelson 7020
                  </p>
                </div>
                <OpeningHours></OpeningHours>
                <p
                  className="essence-orange contact-number-tablet"
                  alt="Contact Number: 022 646 9949"
                >
                  022 646 9949
                </p>
              </div>
              <div className="footer-container-third top-margin">
                <Link className="footer-link" to="/">
                  Home
                </Link>
                <Link className="footer-link" to="/about">
                  About
                </Link>
                <Link className="footer-link" to="/services">
                  Services
                </Link>
                <Link className="footer-link" to="/vouchers">
                  Vouchers
                </Link>
                <Link className="footer-link" to="/privacy">
                  Privacy
                </Link>
                <Link className="footer-link" to="/contact">
                  Contact
                </Link>
                <Link className="footer-link" to="/cancellation">
                  Cancellations
                </Link>
                <a
                  className="footer-link"
                  href={newClientPdf}
                  download="New-Client-Intake-Form"
                >
                  New Client Intake Form
                </a>
                <a
                  className="footer-link"
                  href={pregnancyIntakePdf}
                  download="Pregnancy-Intake-Form"
                >
                  Pregnancy Intake Form
                </a>
              </div>
            </div>
            <div className="copyright-bar">
              <div className="copyright-container-tablet">
                <div>&copy; Copyright 2024 Essence Massage and Bodyworks</div>
                <div>Website Developed and Maintained by Matt Tucker</div>
              </div>
            </div>
          </MediaQuery>
        </MediaQuery>
        <MediaQuery maxWidth={714}>
          <div className="footer-container-mobile">
            <div className="footer-container-slice">
              <p
                className="p-mobile-padding"
                alt="Essence Massage &amp; Bodyworks"
              >
                Essence Massage &amp; Bodyworks
              </p>
              <p
                className="essence-orange p-mobile-padding"
                alt="Contact Number: 022 646 9949"
              >
                022 646 9949
              </p>

              <Link className="footer-link-mobile" to="/contact">
                <div className="messageContactContainer">
                  <p className="essence-orange">
                    <strong>SEND US A MESSAGE</strong>
                  </p>
                </div>
              </Link>
            </div>
            <div className="footer-container-slice">
              <p alt={address + ", Nelson 7020"}>
                {address}
                <br /> Nelson 7020
              </p>
              <OpeningHours></OpeningHours>
            </div>
            <div className="footer-container-slice">
              <Link className="footer-link top-margin" to="/privacy">
                Privacy
              </Link>
              <Link className="footer-link" to="/cancellation">
                Cancellations
              </Link>
              <a
                className="footer-link"
                href={newClientPdf}
                download="New-Client-Intake-Form"
              >
                New Client Intake Form
              </a>
              <a
                className="footer-link"
                href={pregnancyIntakePdf}
                download="Pregnancy-Intake-Form"
              >
                Pregnancy Intake Form
              </a>
            </div>
          </div>
          <div className="copyright-bar-mobile">
            <div className="copyright-container-mobile">
              <div>&copy; Copyright 2024</div>
              <div>Essence Massage and Bodyworks</div>
              <div>Website Developed by Matt Tucker</div>
            </div>
          </div>
        </MediaQuery>
      </footer>
    );
  }
}

export default Footer;
