import { Component, Fragment } from "react";

class PrivacyContent extends Component {
  render() {
    return (
      <Fragment>
        <h1>Privacy</h1>
        <p>
          <b>We are committed to protecting your privacy.</b>
        </p>
        <p>
          <b>Purpose</b> <br />
          The purpose of this privacy policy is to explain how and when we
          collect personal information, what we do with it, and your right to
          see or change it. <br />
          Essence Massage &amp; Bodyworks recognise their responsibilities under
          the Privacy Act 1993 and is committed to ensuring the privacy of your
          information. This Privacy Policy applies to all personal or other
          information collected on Essence Massage &amp; Bodyworks Website –
          www.essencemassage.co.nz
        </p>
        <p>
          <b>Collection, storage and use of personal information</b> <br />
          No need to disclose personal information. <br />
          You can use the website without disclosing any personal information.
        </p>
        <p>
          <b>Your disclosure of personal information</b> <br />
          You may choose to disclose personal information to us when you fill in
          any forms on this website. This information can be viewed by website
          administrators, and our staff, or contractors who work for us.
          Therefore we recommend you don’t send any sensitive personal
          information in submission forms.
        </p>
        <p>
          <b>Holding of information</b> <br />
          If you provide personal information, it is held by us indefinitely. We
          do not sell, publish or give away any customer contact information or
          personal details of any kind to other parties. <br />
          At our sole discretion, we reserve the right to disclose information
          to law enforcement or other government officials if it is demanded in
          connection with an investigation of fraud, intellectual property
          infringements, or other activity that is illegal or may expose us to
          legal liability.
        </p>
        <p>
          <b>Use of personal information</b> <br />
          We’ll only use personal information provided to us for the purpose of
          but not limited to: <br /> <br />
          <ul>
            <li>
              Communicating with you, including, for example, responding to your
              request for information
            </li>
            <li>
              Sending you the occasional newsletter about our business services
            </li>
            <li>Improving the website, and/or improving our services</li>
            <li>Statistical information and cookies</li>
            <li>Statistical information collected</li>
          </ul>
          <br />
          We may collect statistical information about your visit to help us
          improve this website. This information is aggregated and doesn’t
          identify you personally. It may include but is not limited to:
          <br /> <br />
          <ul>
            <li>The search terms you used</li>
            <li>
              The pages you visited on our website and the links you clicked on
            </li>
            <li>The date and time you visited the website</li>
            <li>
              The referring website (if any) from which you clicked through to
              this website
            </li>
          </ul>
        </p>
        <p>
          <b>Use of statistical information</b> <br />
          The statistical information referred to above can be viewed by us and
          our website provider, and be used to improve our website and/or
          overall website services.
        </p>
        <p>
          <b>Cookies</b> <br />
          This website generates session cookies for the purpose of monitoring
          website usage. The cookies don’t collect personal information. You can
          disable them or clear them out of your web browser without affecting
          your ability to use the website.
        </p>
        <p>
          <b>Correcting your personal information</b> <br />
          Your rights: <br />
          Under the Privacy Act 1993, you have the right to access and ask us to
          correct or delete any of your personal information provided to us in
          connection with your use of this website. Contact us if:
          <ul>
            <li>
              You would like to see or change your personal information that you
              provided
            </li>
            <li>You have any concerns regarding your privacy</li>
          </ul>
          <br />
          The Privacy Act 1993 sets out 12 information privacy principles
          dealing with the collection, holding, use and disclosure of personal
          information, and the assigning of unique identifiers, access to and
          correction of personal information. The 12 principles are:
          <ol>
            <li>Purposes of collection of personal information.</li>
            <li>Sources of personal information.</li>
            <li>Collection of information from the subject.</li>
            <li>The manner of collection of personal information.</li>
            <li>Storage and Security of personal information.</li>
            <li>Access to personal information.</li>
            <li>Correction of personal information.</li>
            <li>
              The accuracy of personal information is to be checked before use.
            </li>
            <li>
              Agencys are not to keep personal information for longer than
              necessary.
            </li>
            <li>Limits on use of personal information.</li>
            <li>Limits on disclosure of personal information.</li>
            <li>Use of unique identifiers.</li>
          </ol>
          For more information please refer to the Privacy Act 1993, the Privacy
          Commissioners website www.privacy.org.nz or call the Privacy
          Commissioner’s Enquiries Line: 0800 803 909.
        </p>
        <p>
          Essence Massage &amp; Bodyworks reserve the right to modify or amend
          this Privacy Statement at any time and for any reason. By using this
          site you consent to our use of your personal data in accordance with
          this Privacy Statement (as amended from time to time).
        </p>
      </Fragment>
    );
  }
}
export default PrivacyContent;
