import { Component, Fragment } from "react";
import newClientPdf from "../Files/New-Client-Intake-Form.pdf";
import pregnancyIntakePdf from "../Files/Pregnancy-Intake-Form.pdf";

class CancellationContent extends Component {
  render() {
    return (
      <Fragment>
        <h1>Cancellations</h1>
        <p>
          Essence Massage &amp; Bodyworks appreciates that you chose our
          facility for your massage and bodywork session. It is our absolute
          intention to provide top quality and personal care. In order to do
          that, the hour that you scheduled is set aside for only you. We’d like
          to communicate some guidelines and policies in order to provide you an
          optimum session.
        </p>
        <p>
          New clients please arrive five minutes prior to your scheduled
          appointment to allow time to complete the required health form. This
          form can be found at the bottom of this page, if you would like to
          complete, print and bring it along with you to your first massage
          appointment. We ask all returning clients to arrive a few minutes
          prior to their appointment to ensure they arrive on time. This allows
          your mind and body to start transitioning from an active busy mode to
          a more relaxed receiving mode, thereby enhancing your massage
          experience.
        </p>
        <p>
          <b>Late Policy</b> <br />
          We understand that sometimes issues occur. It helps us a great deal if
          you call or message to let us know you’re running late. We will do our
          best to accommodate you. However please note if you arrive late your
          session may be shortened to accommodate others whose appointments
          follow yours. Regardless of the length of the treatment actually
          provided, you will be responsible for the “full” session.
        </p>
        <p>
          <b>Cancellation Policy</b> <br />
          Essence Massage &amp; Bodyworks asks that you respectfully{" "}
          <b>
            give 24 hours notice of cancellation by phoning us on 022 646 9949
          </b>
          <br />
          If less than 24 hours notice is given to cancel or reschedule your
          appointment you will be invoiced the cancellation fee of half the cost
          of your massage treatment.
          <br />
          <br />
          If no notice is given for your appointment you will be invoiced the
          cancellation fee of the <b>full</b> cost of your massage appointment.
        </p>
        <p>
          <b>Gift Voucher Cancellations</b> <br />
          If less than 24 hours notice is given to reschedule your massage, your
          gift voucher will become void. <br />
          <br />
          Cancellation fees are in effect to ensure that our therapists are
          compensated for their time. As a busy clinic we often turn away
          clients, please keep in mind that if inadequate notice is given it is
          extremely difficult to fill vacant spots. This not only negatively
          impacts our therapists, but also affects our clients. Please be
          courteous to your therapist and other clients by respecting our
          policies.
        </p>
        <p>
          <b>Same Day Appointments</b> <br />
          Please be aware that when booking an appointment on the same day, it
          is considered set, and if changed, cancelled or rescheduled at any
          point after it is made, the client is responsible for paying the full
          price of the appointment.
        </p>
        <p>
          <a
            className="link"
            href={newClientPdf}
            download="New-Client-Intake-Form"
          >
            New Client Intake Form
          </a>
          <br />
          <br />
          <a
            className="link"
            href={pregnancyIntakePdf}
            download="Pregnancy-Intake-Form"
          >
            Pregnancy Intake Form
          </a>
        </p>
      </Fragment>
    );
  }
}
export default CancellationContent;
