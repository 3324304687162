import React from "react";

const VouchersIntro = (props) => {
  return (
    <React.Fragment>
      <h1>Vouchers</h1>
      <p>
        <strong>Happy Birthday! Congratulations! Thank You!</strong>
        <br></br>
        With so many healthy benefits, a Massage Gift Voucher is the perfect
        idea for any occasion.
      </p>
      <p>
        <strong>Online gift voucher purchase: </strong>
        Your online gift voucher will be automatically sent to the recipients
        e-mail address or to your own address for them to use as soon as they
        like! They can book online under our redeem your gift voucher option and
        will need to either print out a copy or note down their unique code.{" "}
        <strong>Our gift vouchers are valid for 6 months!</strong> – Plenty of
        time for them to book in. We take payment via TimelyPay, so please have
        your card details ready.
      </p>
      <p>
        <strong>In-clinic: </strong>
        We sell gift vouchers in clinic so feel free to speak to your therapist
        to purchase a gift card at your next appointment. If you were planning
        to pop in please note we require you to organise a suitable time to come
        in to purchase a gift card. We do not post vouchers to recipients.
      </p>
    </React.Fragment>
  );
};

export default VouchersIntro;
