import React from "react";
import { Component } from "react";
import Map from "./map";

class MapComponent extends Component {
  constructor() {
    super();
    this.state = {
      isMarkerShown: false,
    };
  }

  render() {
    return <Map zoom={19} center={{ lat: -41.340251, lng: 173.209409 }} />;
  }
}

export default MapComponent;
