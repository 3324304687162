import "./Cancellation.css";
import { Component } from "react";
import MediaQuery from "react-responsive";
import banner from "./Images/clock.jpg";
import CancellationContent from "./CancellationContent.js";

class Cancellation extends Component {
  render() {
    return (
      <div className="cancellation">
        <MediaQuery minWidth={1224}>
          <div className="banner-container">
            <img className="banner-image" src={banner} alt="Back Massage" />
          </div>
          <div className="contentBody">
            <div className="cancellation-body">
              <CancellationContent />
            </div>
          </div>
        </MediaQuery>

        <MediaQuery maxWidth={1224}>
          <MediaQuery minWidth={414}>
            <div className="contentBody">
              <div className="cancellation-body-tablet">
                <CancellationContent />
              </div>
            </div>
          </MediaQuery>
        </MediaQuery>
        <MediaQuery maxWidth={414}>
          <div className="contentBody">
            <div className="cancellation-body-mobile">
              <CancellationContent />
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default Cancellation;
