import { Component } from "react";
import React from "react";
import "./Faq.css";
import MediaQuery from "react-responsive";
import banner from "../About/Images/About-Cropped.jpeg";
class FAQ extends Component {
  render() {
    return (
      <div className="faq">
        <MediaQuery minWidth={1224}>
          <div className="banner-container">
            <img className="banner-image" src={banner} alt="Back Massage" />
          </div>
          <div className="contentBody">
            <div className="faq-body">
              <h1>FREQUENTLY ASKED QUESTIONS</h1>
              <ul>
                <li>
                  <em>
                    <strong>Do we offer couples massage?</strong>
                  </em>
                  <p>
                    We don’t offer couples massage where you are in the same
                    treatment room together but we can offer two massages in
                    separate treatment rooms at the same time.
                  </p>
                </li>
                <li>
                  <em>
                    <strong>
                      Can I get a massage if I’m under 18 years of age?
                    </strong>
                  </em>
                  <p>
                    Yes you sure can! Please note you will require a parent or
                    guardian to complete our minors consent form, depending on
                    your therapist and age you may also require a guardian to be
                    presdent during your initial consultation. It is best to
                    give us a call once you have made your appointment.
                  </p>
                </li>
                <li>
                  <em>
                    <strong>
                      Do you have a special pregnancy table which allows me to
                      lay on my front?
                    </strong>
                  </em>
                  <p>
                    Yes both our massage tables have speciality inserts which
                    allow your bump and chest to be supported while lying face
                    down. You do not need to request this table during booking,
                    simple let your therapist if you prefer the speciality
                    pregnancy table or side-lying position.
                  </p>
                </li>
                <li>
                  <em>
                    <strong>
                      Can I pop into the clinic to purchase a Gift Voucher?
                    </strong>
                  </em>
                  <p>
                    We don’t have a reception so we kindly request you to phone
                    us to arrange a specific time when a therapist has a break
                    between clients to sell you a voucher.
                  </p>
                </li>
                <li>
                  <em>
                    <strong>
                      Are Covid vaccination passports required to receive a
                      massage?
                    </strong>
                  </em>
                  <p>
                    Yes all clients in all traffic light colours will require a
                    vaccine passport as per government regulations.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
          <div className="contentBody">
            <div className="faq-body-mobile">
              <h1>FAQS</h1>
              <ul className="faq-list-mobile">
                <li>
                  <em>
                    <strong>Do we offer couples massage?</strong>
                  </em>
                  <p>
                    We don’t offer couples massage where you are in the same
                    treatment room together but we can offer two massages in
                    separate treatment rooms at the same time.
                  </p>
                </li>
                <li>
                  <em>
                    <strong>
                      Can I get a massage if I’m under 18 years of age?
                    </strong>
                  </em>
                  <p>
                    Yes you sure can! Please note you will require a parent or
                    guardian to complete our minors consent form, depending on
                    your therapist and age you may also require a guardian to be
                    presdent during your initial consultation. It is best to
                    give us a call once you have made your appointment.
                  </p>
                </li>
                <li>
                  <em>
                    <strong>
                      Do you have a special pregnancy table which allows me to
                      lay on my front?
                    </strong>
                  </em>
                  <p>
                    Yes both our massage tables have speciality inserts which
                    allow your bump and chest to be supported while lying face
                    down. You do not need to request this table during booking,
                    simple let your therapist if you prefer the speciality
                    pregnancy table or side-lying position.
                  </p>
                </li>
                <li>
                  <em>
                    <strong>
                      Can I pop into the clinic to purchase a Gift Voucher?
                    </strong>
                  </em>
                  <p>
                    We don’t have a reception so we kindly request you to phone
                    us to arrange a specific time when a therapist has a break
                    between clients to sell you a voucher.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default FAQ;
