import { Component } from "react";
import "./Service.css";
import MediaQuery from "react-responsive";
import EssenceService from "../Utils/essence-services";
import EssenceServiceMobile from "../Utils/essence-services-mobile";
import banner from "./Images/Services-Cropped.jpeg";

class Service extends Component {
  // constructor() {
  // 	super();

  // }

  render() {
    return (
      <div className="services">
        <MediaQuery minWidth={1224}>
          <div className="banner-container">
            <img className="banner-image" src={banner} alt="Back Massage" />
          </div>
          <div className="contentBody">
            <div className="service-body">
              <h1>Services</h1>
              <EssenceService />
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
          <MediaQuery minWidth={414}>
            <EssenceService />
          </MediaQuery>
        </MediaQuery>
        <MediaQuery maxWidth={414}>
          <div className="contentBody">
            <div className="service-body-mobile">
              <h1>Services</h1>
              <EssenceServiceMobile />
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default Service;
