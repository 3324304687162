import React from "react";
import { Link } from "react-router-dom";

const ContactIntro = (props) => {
  return (
    <React.Fragment>
      <p>100 Champion Road, Richmond, Nelson 7020</p>
      <p>
        If you have any questions about our services or which massage treatment
        would be best suited to you, please feel welcome to contact us using the
        form below.
      </p>
      <strong>Bookings are by appointment only. No walk-ins please.</strong>
      <p>
        If you wish to make a booking, check out our massage{" "}
        <Link className="link" to="/services">
          services
        </Link>{" "}
        and click book now to access our online schedule.
      </p>
      <p>
        We have a{" "}
        <Link className="link" to="/cancellation">
          cancellation policy
        </Link>{" "}
        which states{" "}
        <em>
          no cancellations or changes are allowed within 24 hours of the
          appointment
        </em>
        .<br></br>
        Please let us know as soon as possible if you can no longer attend your
        appointment. If 24 hours notice isn’t given you will be charged a
        cancellation fee. We greatly appreciate your cooperation.
      </p>
      <strong>Onsite Parking Available</strong>
    </React.Fragment>
  );
};

export default ContactIntro;
