import { Component } from "react";
import Select from "react-select";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import "./Service.css";

class ServiceTemplate extends Component {
  constructor(props) {
    super(props);

    const treatmentOptions = this.props.treatmentOptions;
    this.state = {
      treatmentValue: treatmentOptions[0].value,
      treatmentCost: treatmentOptions[0].cost,
      treatmentId: treatmentOptions[0].id,
    };
    this.setSelectedTreatment = this.setSelectedTreatment.bind(this);
  }

  setSelectedTreatment(treatment) {
    this.setState({
      treatmentValue: treatment.value,
      treatmentCost: treatment.cost,
      treatmentId: treatment.id,
    });
  }

  render() {
    const { title, description, image, treatmentOptions } = this.props;
    const { treatmentCost, treatmentId } = this.state;
    return (
      <div className="contentBody">
        <MediaQuery minWidth={1224}>
          <div className="service-body">
            <h1>{title}</h1>
            {description.map((x) => (
              <p>{x}</p>
            ))}
            <div className="service-row">
              <img className="service-image" src={image} alt={title}></img>
              <div className="book-now-container">
                <div className="flex-row">
                  <strong className="label-length">Treatment length:</strong>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={treatmentOptions[0]}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isSearchable={true}
                    name="color"
                    options={treatmentOptions}
                    onChange={this.setSelectedTreatment}
                  />
                </div>
                <div className="flex-row">
                  <strong className="label-length">Cost:</strong>
                  <div className="basic-single">${treatmentCost}</div>
                </div>
                <Link
                  className="home-links"
                  to={{
                    pathname: `/modal/book/${treatmentId}`,
                    state: { modal: true },
                  }}
                >
                  <div className="buy-now">
                    <p>
                      <strong>BOOK NOW</strong>
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
          <MediaQuery minWidth={414}>
            <div className="service-body-tablet">
              <h1>{title}</h1>
              {description.map((x) => (
                <p>{x}</p>
              ))}
              <img
                className="service-image-tablet"
                src={image}
                alt={title}
              ></img>
              <div className="book-now-container-tablet">
                <div className="flex-row">
                  <strong className="label-length">Treatment length:</strong>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={treatmentOptions[0]}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isSearchable={true}
                    name="color"
                    options={treatmentOptions}
                    onChange={this.setSelectedTreatment}
                  />
                </div>
                <div className="flex-row">
                  <strong className="label-length">Cost:</strong>
                  <div className="basic-single">${treatmentCost}</div>
                </div>
                <Link
                  className="home-links"
                  to={{
                    pathname: `/modal/book/${treatmentId}`,
                    state: { modal: true },
                  }}
                >
                  <div className="buy-now-tablet">
                    <p>
                      <strong>BOOK NOW</strong>
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </MediaQuery>
        </MediaQuery>
        <MediaQuery maxWidth={414}>
          <div className="service-body-mobile">
            <h1>{title}</h1>
            {description.map((x) => (
              <p>{x}</p>
            ))}
            <div className="service-column">
              <img
                className="service-image-mobile"
                src={image}
                alt={title}
              ></img>
              <div className="book-now-container-mobile">
                <div className="flex-row-mobile">
                  <strong className="label-length">Treatment length:</strong>
                  <Select
                    classNamePrefix="select"
                    defaultValue={treatmentOptions[0]}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isSearchable={true}
                    name="color"
                    options={treatmentOptions}
                    onChange={this.setSelectedTreatment}
                  />
                </div>
                <div className="flex-row">
                  <strong>Cost:</strong>
                  <div className="service-price-mobile">${treatmentCost}</div>
                </div>
                <Link
                  className="home-links"
                  to={{
                    pathname: `/modal/book/${treatmentId}`,
                    state: { modal: true },
                  }}
                >
                  <div className="buy-now-mobile">
                    <p>
                      <strong>BOOK NOW</strong>
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default ServiceTemplate;
