import "./Contact.css";
import { Component } from "react";
import ContactIntro from "./ContactIntro";
import OpeningHours from "../Footer/OpeningHours";
import MediaQuery from "react-responsive";
import banner from "./Images/ContactBanner-Cropped.jpeg";
import MapComponent from "../Components/map-logic";
import ReCAPTCHA from "react-google-recaptcha";

class Contact extends Component {
  constructor() {
    super();

    this.state = {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      message: "",
      recaptchaKey: null,
      showLoading: false,
      showMessageSent: false,
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFirstNameChange = this.onFirstNameChange.bind(this);
    this.onLastNameChange = this.onLastNameChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPhoneChange = this.onPhoneChange.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
    this.onReCaptchaChange = this.onReCaptchaChange.bind(this);
  }

  onFirstNameChange(e) {
    this.setState({ firstName: e.target.value });
  }

  onLastNameChange(e) {
    this.setState({ lastName: e.target.value });
  }

  onPhoneChange(e) {
    this.setState({ phone: e.target.value });
  }

  onEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  onMessageChange(e) {
    this.setState({ message: e.target.value });
  }

  onReCaptchaChange(value) {
    this.setState({ recaptchaKey: value });
  }

  onMessageSent() {
    this.setState({
      showLoading: false,
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      message: "",
      recaptchaKey: null,
      showMessageSent: true,
    });
  }

  render() {
    const {
      firstName,
      lastName,
      email,
      phone,
      message,
      recaptchaKey,
      showLoading,
      showMessageSent,
    } = this.state;
    return (
      <div className="contact">
        <MediaQuery minWidth={1224}>
          <div className="banner-container">
            <img className="banner-image" src={banner} alt="Back Massage" />
          </div>
          <div className="contentBody">
            <div className="contact-body">
              <ContactIntro />
              <div className="location-container">
                <div className="hours">
                  <h2>Location &amp; Hours</h2>
                  <h3>Nelson</h3>
                  <OpeningHours />
                </div>
                <div className="google-map">
                  <MapComponent></MapComponent>
                </div>
              </div>
              <div className="contact-form">
                <h2>Contact Form</h2>
                <div className="details-field">
                  <div className="details-container">
                    <input
                      placeholder="First Name"
                      type="text"
                      className="text-input"
                      tabIndex="1"
                      value={firstName}
                      onChange={this.onFirstNameChange}
                    />
                  </div>
                  <div className="details-container">
                    <input
                      placeholder="Last Name"
                      type="text"
                      className="text-input"
                      tabIndex="2"
                      value={lastName}
                      onChange={this.onLastNameChange}
                    />
                  </div>
                </div>
                <div className="details-field">
                  <div className="details-container">
                    <input
                      type="text"
                      className="text-input"
                      placeholder="Phone Number"
                      tabIndex="3"
                      value={phone}
                      onChange={this.onPhoneChange}
                    />
                  </div>
                  <div className="details-container">
                    <input
                      type="email"
                      className="email-input"
                      placeholder="Email Address"
                      tabIndex="4"
                      value={email}
                      onChange={this.onEmailChange}
                    />
                  </div>
                </div>
                <textarea
                  placeholder="Please provide some information about your enquiry"
                  className="message"
                  name="message"
                  tabIndex="4"
                  value={message}
                  onChange={this.onMessageChange}
                />
                <div className="contact-recaptcha">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={this.onReCaptchaChange}
                  />
                </div>
                {showLoading ? (
                  <div
                    className="contact-form-button"
                    disabled={recaptchaKey === null}
                  >
                    <div className="loader"></div>
                  </div>
                ) : (
                  <div
                    className="contact-form-button"
                    disabled={recaptchaKey === null}
                    onClick={() => this.onFormSubmit()}
                  >
                    Enquire Now!
                  </div>
                )}
                {showMessageSent && (
                  <div className="message-sent">
                    Your message has been sent successfully. We will be in touch
                    soon!
                  </div>
                )}
              </div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
          <div className="contentBody">
            <div className="contact-body">
              <h1 className="contact-heading1-mobile">Contact</h1>
              <h2 className="contact-heading2-mobile">
                Essence Massage &amp; Bodyworks
              </h2>
              <ContactIntro />
              <div className="location-container-mobile">
                <div className="hours-mobile">
                  <h2>Location &amp; Hours</h2>
                  <h3>Nelson</h3>
                  <OpeningHours />
                </div>
                <div className="google-map-mobile">
                  <MapComponent></MapComponent>
                </div>
              </div>
              <div className="contact-form">
                <h2>Contact Form</h2>
                <div className="details-field-mobile">
                  <input
                    placeholder="First Name"
                    type="text"
                    className="text-input-mobile"
                    tabIndex="1"
                    value={firstName}
                    onChange={this.onFirstNameChange}
                  />
                  <input
                    placeholder="Last Name"
                    type="text"
                    className="text-input-mobile"
                    tabIndex="2"
                    value={lastName}
                    onChange={this.onLastNameChange}
                  />
                  <input
                    type="text"
                    className="text-input-mobile"
                    placeholder="Phone Number"
                    tabIndex="3"
                    value={phone}
                    onChange={this.onPhoneChange}
                  />
                  <input
                    type="email"
                    className="text-input-mobile"
                    placeholder="Email Address"
                    tabIndex="4"
                    value={email}
                    onChange={this.onEmailChange}
                  />
                  <textarea
                    placeholder="Please provide some information about your enquiry"
                    className="message"
                    name="message"
                    tabIndex="4"
                    value={message}
                    onChange={this.onMessageChange}
                  />
                  <div className="contact-recaptcha">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                      onChange={this.onReCaptchaChange}
                    />
                  </div>
                  {showLoading ? (
                    <div
                      className="contact-form-button"
                      disabled={recaptchaKey === null}
                    >
                      <div className="loader"></div>
                    </div>
                  ) : (
                    <div
                      className="contact-form-button-mobile"
                      disabled={recaptchaKey === null}
                      onClick={() => this.onFormSubmit()}
                    >
                      Enquire Now!
                    </div>
                  )}
                  {showMessageSent && (
                    <div className="message-sent">
                      Your message has been sent successfully. We will be in
                      touch soon!
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }

  async onFormSubmit() {
    this.setState({ showLoading: true });
    const code = {
      key: this.state.recaptchaKey,
    };
    const domainUrl = window.location.host;
    let reCaptchaResponse = await fetch(
      `https://${domainUrl}/api/validateRecaptcha`,
      {
        method: "POST",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(code),
      }
    );

    if (reCaptchaResponse.ok) {
      const { firstName, lastName, phone, email, message } = this.state;
      const data = {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        email: email,
        message: message,
      };
      let response = fetch(`https://${domainUrl}/api/sendGridEmail`, {
        method: "POST",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      this.onMessageSent();
      console.log(response);
    } else {
      alert("ReCaptcha Fail");
      console.log(reCaptchaResponse);
    }
  }
}

export default Contact;
