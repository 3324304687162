import React, { useState } from "react";

function MobileNav(props) {
  const [selectedPage, setPage] = useState("home");
  let pathName = window.location.pathname;
  let splitPath = pathName.split("/");
  let path = splitPath[1];
  React.useEffect(() => {
    setPage(path);
  }, [path]);

  console.log("Selected Page: " + selectedPage);
  return (
    <div
      className={
        props.menuStage === "open"
          ? "hamburger-menu open-menu"
          : "hamburger-menu"
      }
    >
      <ul className="menu-list">
        <li
          onClick={() => props.onMenuItemClicked("home")}
          className={
            props.menuStage === "open" ? "menu-item" : "menu-item-closed"
          }
        >
          Home
        </li>
        <li
          onClick={() => props.onMenuItemClicked("about")}
          className={
            props.menuStage === "open" ? "menu-item" : "menu-item-closed"
          }
        >
          About
        </li>
        <li
          onClick={() => props.onMenuItemClicked("service")}
          className={
            props.menuStage === "open" ? "menu-item" : "menu-item-closed"
          }
        >
          Services
        </li>
        <li
          onClick={() => props.onMenuItemClicked("vouchers")}
          className={
            props.menuStage === "open" ? "menu-item" : "menu-item-closed"
          }
        >
          Vouchers
        </li>
        <li
          onClick={() => props.onMenuItemClicked("faq")}
          className={
            props.menuStage === "open" ? "menu-item" : "menu-item-closed"
          }
        >
          FAQs
        </li>
        <li
          onClick={() => props.onMenuItemClicked("contact")}
          className={
            props.menuStage === "open" ? "menu-item" : "menu-item-closed"
          }
        >
          Contact
        </li>
      </ul>
    </div>
  );
}

export default MobileNav;
